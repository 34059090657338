import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core/'

import cppNodejs from './cpp_in_nodejs_logo.png'
import cppPython from './cpp_in_python_logo.png'
import cppAgent from './cpp_agent_logo.png'
import nodejsAgent from './nodejs_agent_logo.png'
import nodejsClient from './nodejs_client_logo.png'
import reactApp from './nodejs_react_logo.png'
import arduinoAgent from './arduino_agent_logo.png'
import rAgent from './r_agent_logo.png'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    width: '500px'
  },
  text: {
    paddingBottom: theme.spacing(2)
  },
  card: {
    maxWidth: 320,
    margin: theme.spacing(2)
  },
  actionArea: {
    minHeight: 300
  },
  media: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 120,
    height: 120
  }
})

function ExampleCards ({ classes, history }) {
  return (
    <div className={classes.root}>
      <div className={classes.cards} >
        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/c++-in-nodejs') }}
          >
            <CardMedia
              className={classes.media}
              image={cppNodejs}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                C++ embedded in Node.js
              </Typography>
              <Typography
                className={classes.text}
                align='justify'
                component='p'
              >
                Shows how to directly call C++ code from Node.js.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/c++-in-python') }}
          >
            <CardMedia
              className={classes.media}
              image={cppPython}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                C++ embedded in Python3
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to directly call C++ code from Python3.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/c++-agent') }}
          >
            <CardMedia
              className={classes.media}
              image={cppAgent}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                C++ Agent
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
               Shows how to make any existing C++ code callable from remote.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/arduino-agent') }}
          >
            <CardMedia
              className={classes.media}
              image={arduinoAgent}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Arduino Agent
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to make any existing Arduino code callable from remote.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/r-agent') }}
          >
            <CardMedia
              className={classes.media}
              image={rAgent}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                R Agent
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to make any existing R code callable from remote.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/node-agent') }}
          >
            <CardMedia
              className={classes.media}
              image={nodejsAgent}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Node.js Agent
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to make any existing Node.js code callable from remote.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/node-client') }}
          >
            <CardMedia
              className={classes.media}
              image={nodejsClient}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Node.js Client
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to call any agent code from Node.js.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/examples/react-app') }}
          >
            <CardMedia
              className={classes.media}
              image={reactApp}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                React App
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Shows how to communicate between frontend and backend.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styleSheet)(ExampleCards))
