import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styleSheet = (theme) => ({
  '@keyframes letterAppear': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  row: {
    display: 'flex',
    wrap: 'nowrap',
    flexDirection: 'row'
  },
  letter: {
    display: 'flex',
    wrap: 'nowrap',
    flexDirection: 'column',
    paddingRight: '6px',
    animationName: 'letterAppear',
    animationDuration: '1.5s',
    backgroundColor: '#121212'
  },
  word: {
    display: 'flex',
    wrap: 'nowrap',
    flexDirection: 'row',
    minWidth: '250px',
    marginTop: '32px',
    marginBottom: '32px'
  }
})

class Logo extends React.Component {
  constructor (props) {
    super(props)
    this._alphabet = new Map()
    this._alphabet.set('v', [['bl', 'e', 'e', 'br'], ['f', 'bl', 'br', 'f'], ['tr', 'f', 'f', 'tl'], ['e', 'tr', 'tl', 'e']])
    this._alphabet.set('r', [['f', 'f', 'e'], ['f', 'f', 'e'], ['f', 'tr', 'bl'], ['f', 'e', 'tr']])
    this._alphabet.set('p', [['f', 'f'], ['f', 'f'], ['f', 'e'], ['f', 'e']])
    this._alphabet.set('c', [['e', 'br', 'f', 'tl'], ['br', 'f', 'tl', 'e'], ['tr', 'f', 'bl', 'e'], ['e', 'tr', 'f', 'bl']])
    this.state = { word: '' }
  }

  renderWord (word, fontSize = 10, colors = ['#ffffff']) {
    const { classes } = this.props
    const letters = word.split('')
    return (
      <div className={classes.word}>
        {
          letters.map((letter, lid) => {
            const encoding = this._alphabet.get(letter)
            const color = lid < colors.length ? colors[lid] : colors[0]
            return (
              <div className={`${classes.letter}`} key={lid}>
                {
                  encoding.map((row, rid) => {
                    return (
                      <div className={classes.row}>
                        {
                          row.map((pixel, pid) => {
                            let borderColor
                            switch (pixel) {
                              case 'tl':
                                borderColor = `${color} transparent transparent ${color}`
                                break
                              case 'tr':
                                borderColor = `${color} ${color} transparent transparent`
                                break
                              case 'br':
                                borderColor = `transparent ${color} ${color} transparent`
                                break
                              case 'bl':
                                borderColor = `transparent transparent ${color} ${color}`
                                break
                              case 'e':
                                borderColor = 'transparent'
                                break
                              case 'f':
                                borderColor = color
                                break
                              default:
                                // Should not happen
                                borderColor = color
                            }
                            return (
                              <div
                                style={{
                                  borderColor,
                                  width: 0,
                                  height: 0,
                                  borderStyle: 'solid',
                                  borderWidth: `${fontSize}px`
                                }}
                                key={`${lid}-${rid}-${pid}`} />
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  componentDidMount () {
    setTimeout(() => this.setState({ word: 'v' }), 300)
    setTimeout(() => this.setState({ word: 'vr' }), 600)
    setTimeout(() => this.setState({ word: 'vrp' }), 900)
    setTimeout(() => this.setState({ word: 'vrpc' }), 1200)
  }

  render () {
    return this.renderWord(this.state.word, 8, ['#cab350', '#ffffb0', '#e4d97f', '#cab350'])
  }
}

export default withStyles(styleSheet)(Logo)
