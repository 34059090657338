import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Paper
} from '@material-ui/core/'

import embedded from './embedded.jpeg'
import integration from './integration.jpeg'
import microservices from './microservices.jpeg'
import frontendBackend from './frontend-backend.jpeg'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    width: '500px'
  },
  text: {
    paddingBottom: theme.spacing(2),
    fontSize: 15
  },
  card: {
    maxWidth: 350,
    margin: theme.spacing(2)
  },
  media: {
    minHeight: 200
  }
})

function UseCases ({ classes, history }) {
  return (
    <div className={classes.root}>
      {false && <Paper className={classes.title} elevation={10}>
        <Typography variant='h3'>
        Use Cases
        </Typography>
      </Paper>
      }
      <div className={classes.cards} >

        <Card raised className={classes.card}>
          <CardActionArea
            onClick={() => { history.push('/use-cases/embedded-systems') }}
          >
            <CardMedia
              className={classes.media}
              image={embedded}
              title='Embedded'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Embedded Systems
              </Typography>
              <Typography
                className={classes.text}
                align='justify'
                component='p'
              >
                Let your embedded code directly be remotely callable and make
                IoT integration a breeze.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            onClick={() => { history.push('/use-cases/c-c++-integration') }}
          >
            <CardMedia
              className={classes.media}
              image={integration}
              title='C/C++ Integration'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                C/C++ Integration
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Directly run C/C++ code in a high-level language such as Node.js
                or Python.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            onClick={() => { history.push('/use-cases/inter-microservices') }}
          >
            <CardMedia
              className={classes.media}
              image={microservices}
              title='Inter Microservices'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Inter Microservices
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Let your microservices talk with each other as if they would run
                in one application.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            onClick={() => { history.push('/use-cases/frontend---backend') }}
          >
            <CardMedia
              className={classes.media}
              image={frontendBackend}
              title='Frontend - Backend'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Frontend - Backend
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                Only implement backend logic, then use it from the frontend directly and
                serverlessly.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styleSheet)(UseCases))
