import React from 'react'
import scrollToComponent from 'react-scroll-to-component'
import { Helmet } from 'react-helmet'
import {
  withStyles
} from '@material-ui/core'

import TopBar from '../app/TopBar'
import EmbeddedSystems from './EmbeddedSystems'
import CppIntegration from './CppIntegration'
import InterMicroservices from './InterMicroservices'
import FrontendBackend from './FrontendBackend'

const styleSheet = theme => ({
  useCases: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  spacer: {
    minHeight: 100
  },
  bottomSpacer: {
    minHeight: 200
  }
})

class UseCases extends React.Component {

  scrollTo (subTitle) {
    scrollToComponent(
      this[subTitle],
      { offset: 0, align: 'middle', duration: 500, ease: 'inSine' }
    )
  }

  render () {
    const { classes, subTitle } = this.props
    setImmediate(() => this.scrollTo(subTitle))
    return (
      <div>
        <Helmet>
          <title>VRPC - Use Cases</title>
          <meta
            name='description'
            content='Use VRPC to remotify your embedded code or machine interface. Use it for inter-microservice and frontend-backend communication.'
          />
        </Helmet>
        <TopBar title='Use Cases'>
          <div className={classes.useCases}>
            <EmbeddedSystems ref={(ref) => (this.embeddedSystems = ref)} />
            <div className={classes.spacer} />
            <CppIntegration ref={(ref) => (this.cppIntegration = ref)} />
            <div className={classes.spacer} />
            <InterMicroservices ref={(ref) => (this.interMicroservices = ref)} />
            <div className={classes.spacer} />
            <FrontendBackend ref={(ref) => (this.frontendBackend = ref)} />
            <div className={classes.bottomSpacer} />
          </div>
        </TopBar>
      </div>
    )
  }
}

export default withStyles(styleSheet)(UseCases)
