import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  withStyles
} from '@material-ui/core'

const styleSheet = theme => ({
  root: {
    display: 'flex'
  },
  subMenu: {
    // backgroundColor: theme.palette.primary.main,
    // opacity: 0.9
  }
})

class HorizontalMenuEntry extends React.Component {

  constructor (props) {
    super(props)
    this.state = { expanded: false }
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle () {
    const { title, subTitles } = this.props
    if (!subTitles) {
      let nav = title.toLowerCase().replace(/ |\//g, '-')
      this.props.history.push(`/${nav}`)
    } else {
      this.setState(state => ({ expanded: !state.expanded }))
    }
  }

  handleClose (event, title, subTitle) {
    if (this.anchorEl.contains(event.target)) return
    this.setState({ expanded: false })
    if (!event.target.id) return
    title = title.toLowerCase().replace(/ |\//g, '-')
    subTitle = subTitle.toLowerCase().replace(/ |\//g, '-')
    this.props.history.push(`/${title}/${subTitle}`)
  }

  render () {
    const { classes, title, subTitles } = this.props
    const { expanded } = this.state

    return (
      <div className={classes.root}>
        <Button
          style={{ color: '#fff' }}
          buttonRef={node => {
            this.anchorEl = node
          }}
          aria-owns={expanded ? 'menu-list-grow' : null}
          aria-haspopup
          onClick={this.handleToggle}
        >
          {title}
        </Button>
        <Popper
          open={expanded}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-list-grow'
              style={{
                transformOrigin: placement === 'bottom'
                ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(ev) => this.handleClose(ev)}>
                  <MenuList>
                    {subTitles.map(subTitle =>
                      <MenuItem
                        key={subTitle}
                        id={subTitle}
                        onClick={(ev) => this.handleClose(ev, title, subTitle)}
                      >
                        {subTitle}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

export default withRouter(withStyles(styleSheet)(HorizontalMenuEntry))
