import { PureComponent } from 'react'
import marked from 'marked'
import renderHTML from 'react-render-html'
import hljs from 'highlight.js'
import 'highlight.js/styles/default.css'

marked.setOptions({
  highlight: (code, language) => {
    if (language) return hljs.highlight(language, code).value
    return hljs.highlightAuto(code).value
  },
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  smartLists: true,
  smartypants: false
})

class MarkdownRenderer extends PureComponent {
  static defaultProps = { text: '' }
  render () {
    const { text } = this.props
    return text ? renderHTML(marked(text)) : null
  }
}

export default MarkdownRenderer
