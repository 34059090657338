import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import ImageText from '../app/ImageText'
import dataTypes from './data-types.svg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class DataTypes extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <ImageText sizeHint='contain' image={dataTypes}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          Data Types
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
        VRPCs main task is to call functions of any programming language from any
        other programming language. This - in general - requires some kind of data
        handling, as functions may consume data (arguments) and provide data
        (return values). Different languages however, have very different
        ideas about data types. Typically, any language provides its own set of fundamental
        types and the possibility to define custom types by composing those
        fundamental ones.
        The figure shows that those types can be very different by comparing for
        example JavaScript (Node.js) with C++.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
        VPRCs solves the datatype mapping problem by using a single type all languages
        support: the <b>string</b> type. The simple and central design idea is to
        express all other types as a string and use it as the only exchange type between
        languages. As recipe for this mapping VRPC uses the world-wide accepted
        standard JSON (JavaScript Object Notation).
        This way data can be shipped across programming languages and
        even across networks.
        </Typography>
      </ImageText>
    )
  }
}

export default withStyles(styleSheet)(DataTypes)
