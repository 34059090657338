import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Drawer,
  Hidden,
  withStyles
} from '@material-ui/core'

import MenuContent from './MenuContent'

const styleSheet = theme => ({
  drawerPaperPermanent: {
    width: 250,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7
  },
  drawerPaperTemporary: {
    width: 250
  }
})
class NavigationBar extends React.Component {

  constructor (props) {
    super(props)
    this.state = { open: false }
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
  }

  handleDrawerToggle () {
    this.setState(state => ({ open: !state.open }))
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) {
      this.handleDrawerToggle()
    }
  }

  render () {
    const { classes, withMenu } = this.props
    // const path = history.location.pathname
    return (
      <div style={{ width: 250 }}>
        <Hidden lgUp>
          <Drawer
            variant='temporary'
            anchor='left'
            open={this.state.open}
            onClose={this.handleDrawerToggle}
            classes={{ paper: classes.drawerPaperTemporary }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <MenuContent onClick={this.handleDrawerToggle} />
          </Drawer>
        </Hidden>
        {withMenu &&
          <Hidden mdDown implementation='css'>
            <Drawer
              variant='permanent'
              open
              classes={{ paper: classes.drawerPaperPermanent }}
            >
              <MenuContent onClick={this.handleDrawerToggle} />
            </Drawer>
          </Hidden>
        }
      </div>
    )
  }
}

export default withRouter(withStyles(styleSheet)(NavigationBar))
