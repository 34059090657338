import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import TextImage from '../app/TextImage'
import microservices from '../home/microservices.jpeg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class InterMicroservices extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <TextImage image={microservices}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
        Inter Microservices
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Microservices are an architectural pattern for designing large but still
          scalable and maintainable software systems. Using a containerization technology
          such as Docker, the basic idea is to split the actual software into
          self-maintained applications called Microservices.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          While this architecture is proven to be a good idea, it also brings some complications
          with it. Often enough Microservices need to communicate with each other
          in order to fulfill higher level tasks. Such communication however must
          always involve a network, as functions of different Microservices do
          not share a common memory space.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Consequently, as network calls take time, such Inter-Microservice communications
          must be treated asynchronously. Even worse, if the remotified function itself
          performs a time-consuming task (such as a database lookup), a second layer
          of asynchronicity must be handled additionally.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Potential solutions such as RESTful APIs, websockets or messaging middleware
          quickly become tedious, as additional code for such communication layers
          must be written and maintained. Even worse, none of those technologies alone support
          the variety of patterns needed to simulate the complexity hidden in regular function calls.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Using VRPC, all this complexity is abstracted allowing a programming style
          as if only regular function were called. VRPC takes care of all complications
          such as network calls, memory handling, exception forwarding, etc.
        </Typography>
      </TextImage>
    )
  }
}

export default withStyles(styleSheet)(InterMicroservices)
