import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import {
  Typography
} from '@material-ui/core'
import UseCasesCards from './UseCaseCards'
import Buttons from './Buttons'
import Logo from './Logo'
import TopBar from '../app/TopBar'

const styleSheet = theme => ({
  '@keyframes forkMeDisappear': {
    from: {
      transform: 'translate(0px, 0px) rotate(0deg)'
    },
    to: {
      transform: 'translate(20px, -128px) rotate(45deg)'
    }
  },
  root: {
    width: '100%',
    height: '100%',
    margin: 0
  },
  grow: {
    flexGrow: 1
  },
  teaser: {
    width: '100%',
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  text: {
    color: '#000000',
    paddingLeft: theme.spacing(4)
  },
  logo: {
    width: '200px'
  },
  forkMe: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: 0,
    zIndex: 10000,
    opacity: 1,
    '-webkit-transition': 'opacity 2s ease-in',
    '-moz-transition': 'opacity 2s ease-in',
    '-o-transition': 'opacity 2s ease-in',
    transition: 'opacity 2s ease-in'
  },
  [theme.breakpoints.down('md')]: {
    forkMeHide: {
      opacity: 0,
      animation: 'forkMeDisappear 2s forwards',
      pointerEvents: 'none'
    }
  }
})

function Home ({ classes }) {
  return (
    <div className={classes.root}>
      <Helmet>
        <title>VRPC - The Internet of Code</title>
        <meta
          name='description'
          content='VRPC connects your existing code to MQTT and makes it callable remotely.'
        />
      </Helmet>
      <a
        className={`${classes.forkMe} ${classes.forkMeHide}`}
        href='https://github.com/bheisen/vrpc'
      >
        <img
          src='https://s3.amazonaws.com/github/ribbons/forkme_left_gray_6d6d6d.png'
          alt='Fork me on GitHub'
        />
      </a>
      <TopBar>
        <div className={classes.teaser}>
          {false && <Logo />}
          <div>
            <Typography className={classes.text} paragraph variant='h3'>
              VRPC
            </Typography>
            <Typography className={classes.text} variant='h5'>
              Connect code. Serverless, schemaless, non intrusive.
            </Typography>
          </div>
        </div>
        <UseCasesCards />
        <Buttons />
      </TopBar>
    </div>
  )
}

export default withStyles(styleSheet)(Home)
