import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import TextImage from '../app/TextImage'
import embedded from '../home/embedded.jpeg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class EmbeddedSystems extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <TextImage image={embedded}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
        Embedded Systems
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          The world is getting smart and millions of yet isolated things need
          to talk to the internet.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          While the hardware is already well prepared and even the smallest
          commercially off-the-shelf microchips and processors provide WiFi
          modules, the efforts on the software side are still immense.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          This is where <b>VRPC</b> renders useful. Once the actual business code is
          written it can be either linked to the VRPC library or loaded to
          the VRPC agent. Both solutions ship as fully self-contained,
          zero-dependency entities with modern TLS 1.2 encryption already
          baked in.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Hence, without touching, existing business code can be
          "remotified" and integrated in a secure and certification ready
          IoT infrastructure.
        </Typography>
      </TextImage>
    )
  }
}

export default withStyles(styleSheet)(EmbeddedSystems)
