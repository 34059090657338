import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core/'
import mqttLogo from './mqtt_logo.png'
import cppLogo from './cpp_logo.png'
import nodejsLogo from './nodejs_logo.png'
import pythonLogo from './python_logo.png'
import reactLogo from './react_logo.png'
import arduinoLogo from './arduino_logo.png'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    width: '500px'
  },
  text: {
    paddingBottom: theme.spacing(2)
  },
  card: {
    minWidth: 280,
    maxWidth: 320,
    margin: theme.spacing(2)
  },
  actionArea: {
    minHeight: 300
  },
  media: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 130,
    width: 130
  }
})

function DocumentationCards ({ classes, history }) {
  return (
    <div className={classes.root}>
      <div className={classes.cards} >
        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/remote-protocol') }}
          >
            <CardMedia
              className={classes.media}
              image={mqttLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Remote Protocol
              </Typography>
              <Typography
                className={classes.text}
                align='justify'
                component='p'
              >
                All the details
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/cpp') }}
          >
            <CardMedia
              className={classes.media}
              image={cppLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                C++
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                VRPC API Reference
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/nodejs') }}
          >
            <CardMedia
              className={classes.media}
              image={nodejsLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Node.js
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                VRPC API Reference
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/python') }}
          >
            <CardMedia
              className={classes.media}
              image={pythonLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Python 3
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                VRPC API Reference
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/react') }}
          >
            <CardMedia
              className={classes.media}
              image={reactLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                React
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                VRPC API Reference
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card raised className={classes.card}>
          <CardActionArea
            className={classes.actionArea}
            onClick={() => { history.push('/docs/arduino') }}
          >
            <CardMedia
              className={classes.media}
              image={arduinoLogo}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Arduino
              </Typography>
              <Typography className={classes.text} align='justify' component='p'>
                VRPC API Reference
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styleSheet)(DocumentationCards))
