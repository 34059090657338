import React from 'react'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import {
  Paper,
  withStyles
} from '@material-ui/core'

const WIDTH = 600

const styleSheet = theme => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  textBox: {
    padding: theme.spacing(5),
    width: WIDTH,
    height: 'auto',
    opacity: 0.7,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
})

function ImageText ({
  children,
  classes,
  image,
  width,
  sizeHint = 'cover'
} = {}) {
  let height = 'auto'
  let elevation = 10
  let marginBottom = 0
  if (isWidthDown('md', width)) {
    marginBottom = '16px'
    height = 280
    elevation = 0
  }
  return (
    <div className={classes.content} style={{ minHeight: height }}>
      <Paper
        square
        elevation={elevation}
        style={{
          height,
          marginBottom,
          width: WIDTH,
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: sizeHint,
          backgroundColor: '#424242'
        }}
      />
      <Paper square elevation={10} className={classes.textBox}>
        {children}
      </Paper>
    </div>
  )
}

export default withStyles(styleSheet)(withWidth()(ImageText))
