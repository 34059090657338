import React from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  withStyles
} from '@material-ui/core'

import ImageText from '../app/ImageText'
import integration from '../home/integration.jpeg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class CppIntegration extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <ImageText image={integration}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          C/C++ Integration
        </Typography>
        <Typography className={classes.body} align='justify' variant='body1'>
          More and more modern software systems utilize high-level programming language
          such as Python or Node.js. While this approach accelerates development time, it
          also has some side-effects:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary='Performance critical tasks may not be realizable with those high-level languages'
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Existing C/C++ libraries for graphical rendering, machine-learning, network protocol encoding, etc. can not easily be used'
            />
          </ListItem>
        </List>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          With VRPC, any C or C++ code can be added to Python's or Node.js' interpreter,
          it then runs natively within the same application. Access to the C/C++ functions
          and objects is proxied into the corresponding high-level language, such that it
          doesn't even feel like executing C/C++ code under the hood.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          The C/C++ code can be coded
          without knowledge about VRPC as the integration is non-intrusive.
          This allows addition of ready-made, high-performance C/C++ libraries (even in form of
          already compiled libraries) without writing binding code.
        </Typography>
      </ImageText>
    )
  }
}

export default withStyles(styleSheet)(CppIntegration)
