import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import TopBar from '../app/TopBar'
import MarkdownRenderer from './MarkdownRenderer'
import logo from './arduino_agent_logo.png'

const styleSheet = theme => ({
  '@global': {
    body: {
      fontSize: '14px',
      lineHeight: 1.65,
      letterSpacing: 0.1,
      fontFamily: 'Source Sans Pro, Lucida Grande, sans-serif'
    },
    a: {
      color: 'darkblue'
    },
    pre: {
      overflow: 'auto',
      padding: theme.spacing(2),
      backgroundColor: '#f5f5f5'
    }
  },
  root: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto'
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
    backgroundColor: '#424242'
  },
  content: {
    padding: theme.spacing(4),
    opacity: 0.9,
    marginBottom: theme.spacing(6)
  },
  img: {
    marginLeft: theme.spacing(4),
    height: 180,
    width: 180
  }
})

class ArduinoAgent extends Component {
  constructor (props) {
    super(props)
    this.state = { example1: null, example2: null }
  }

  async componentDidMount () {
    const repo =
      'https://raw.githubusercontent.com/heisenware/vrpc-arduino/master'
    const ex1 = await window.fetch(`${repo}/examples/Blink_WiFi/Blink_WiFi.ino`)
    const example1 = await ex1.text()
    const ex2 = await window.fetch(
      `${repo}/examples/LCD_Display_WiFi/LCD_Display_WiFi.ino`
    )
    const example2 = await ex2.text()
    const ex3 = await window.fetch(
      `${repo}/examples/Temperature_GSM/Temperature_GSM.ino`
    )
    const example3 = await ex3.text()
    this.setState({
      example1: `# Example 1: WiFi Blink\n\`\`\`c++\n${example1}\`\`\``,
      example2: `# Example 2: LCD Display\n\`\`\`c++\n${example2}\`\`\``,
      example3: `# Example 3: Read Temperature\n\`\`\`c++\n${example3}\`\`\``

    })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <TopBar title='Examples'>
          <div className={classes.root}>
            <Paper elevation={10} className={classes.section}>
              <Typography color='secondary' variant='h4'>
                Arduino Agent
              </Typography>
              <img className={classes.img} alt='arduino-logo' src={logo} />
            </Paper>
            <Paper elevation={10} className={classes.content}>
              <MarkdownRenderer text={this.state.example1} />
            </Paper>
            <Paper elevation={10} className={classes.content}>
              <MarkdownRenderer text={this.state.example2} />
            </Paper>
            <Paper elevation={10} className={classes.content}>
              <MarkdownRenderer text={this.state.example3} />
            </Paper>
          </div>
        </TopBar>
      </div>
    )
  }
}

export default withStyles(styleSheet)(ArduinoAgent)
