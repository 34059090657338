import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core'
import LegalIcon from '@material-ui/icons/AccountBalance'
import ContactIcon from '@material-ui/icons/Email'

const styleSheet = (theme) => ({
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: '0px'
  },
  [theme.breakpoints.up('lg')]: {
    navigationBar: {
      width: '250px'
    },
    footerShift: {
      width: 'calc(100% - 250px)',
      marginLeft: '250px'
    }
  },
  grow: {
    flexGrow: 1
  }
})

function BottomBar ({ classes, withMenu, history }) {
  const footerClass = withMenu ? `${classes.footer} ${classes.footerShift}` : classes.footer
  return (
    <div className={footerClass}>
      <Toolbar>
        <Typography>
          vrpc © 2020 heisenware
        </Typography>
        <div className={classes.grow} />
        <IconButton
          color='inherit'
          aria-label='Legal'
          title='Legal'
          onClick={() => history.push('/legal')}
        >
          <LegalIcon />
        </IconButton>
        {false && <IconButton
          color='inherit'
          aria-label='Contact'
          title='Contact'
        >
          <ContactIcon />
        </IconButton>}
      </Toolbar>
    </div>
  )
}

export default withRouter(withStyles(styleSheet)(BottomBar))
