import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import ImageText from '../app/ImageText'
import frontendBackend from '../home/frontend-backend.jpeg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class FrontendBackend extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <ImageText image={frontendBackend}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          Frontend Backend
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Modern browser-based applications entirely run in the browser, interacting with
          the backend only to communicate plain data. In order to transfer data from and
          to the backend, network calls are necessary. Nowadays, this is accomplished
          either via REST calls (no server push) or using websockets (server may push).
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Both solutions require a substantial amount of code-writing, namely a web-server
          on the backend and manual client calls from the frontend. All error handling and
          asynchronicity has to be programmed and described again to in the end trigger a
          function call on the controller layer of your backend.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          Using VRPC the backend is ready to use, once the public interface of the
          business logic is coded. Backend functions can have callbacks,
          promises, throw exceptions - whatever is needed.
          The frontend can then call the backend directly, without the need of any
          server, the communication happens seamlessly.
        </Typography>
      </ImageText>
    )
  }
}

export default withStyles(styleSheet)(FrontendBackend)
