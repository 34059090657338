import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import scrollToComponent from 'react-scroll-to-component'
import { Helmet } from 'react-helmet'
import {
  Typography
} from '@material-ui/core'
import Technologies from './Technologies'
import Legal from './Legal'
import TopBar from '../app/TopBar'

const styleSheet = theme => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  teaser: {
    width: '100%',
    paddingTop: 20,
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: '8em',
    paddingRight: 20

  },
  text: {
    color: '#000000'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
})

class Download extends React.Component {

  scrollTo (subTitle) {
    scrollToComponent(
      this[subTitle],
      { offset: -100, align: 'top', duration: 500, ease: 'inSine' }
    )
  }

  render () {
    const { classes, subTitle } = this.props
    setTimeout(() => this.scrollTo(subTitle), 100)
    return (
      <div className={classes.root}>
        <Helmet>
          <title>VRPC - Download</title>
          <meta
            name='description'
            content='Download VRPC here for free.'
          />
        </Helmet>
        <TopBar title='Download' />
        <div className={classes.teaser}>
          <div>
            <Typography className={classes.text} paragraph variant='h2'>
              Download VRPC
            </Typography>
            <Typography className={classes.text} variant='h5'>
              and enter the world of seamless communication.
            </Typography>
          </div>
        </div>
        <Technologies />
        <Legal className={classes.footer} />
      </div>
    )
  }
}

export default withStyles(styleSheet)(Download)
