import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography
} from '@material-ui/core'
import BottomBar from '../app/BottomBar'
import TopBar from '../app/TopBar'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  text: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '30px',
    fontWeight: '300',
    textAlign: 'center',
    background: 'rgba(200, 200, 200, 0.3)',
    color: '#000000',
    padding: '20px',
    transition: 'opacity 3s ease',
    '&:hover': {
      opacity: 0
    },
    '&:touch': {
      opacity: 0
    }
  }
})

function NotFound ({ classes }) {
  return (
    <div>
      <TopBar />
      <Typography className={classes.text}>
        here is nothing, not even a 404
      </Typography>
      <BottomBar />
    </div>
  )
}

export default withStyles(styleSheet)(NotFound)
