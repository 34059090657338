import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import createHistory from 'history/createHashHistory'
import NotFound from './NotFound'
import Home from '../home/Home'
import UseCases from '../useCases/UseCases'
import Technology from '../technology/Technology'
import Examples from '../examples/Examples'
import CppNode from '../examples/CppNode'
import CppPython from '../examples/CppPython'
import CppAgent from '../examples/CppAgent'
import ArduinoAgent from '../examples/ArduinoAgent'
import RAgent from '../examples/RAgent'
import NodeAgent from '../examples/NodeAgent'
import NodeClient from '../examples/NodeClient'
import ReactApp from '../examples/ReactApp'
import Documentation from '../documentation/Documentation'
import RemoteProtocol from '../documentation/RemoteProtocol'
import NodeJs from '../documentation/NodeJs'
import Cpp from '../documentation/Cpp'
import Python from '../documentation/Python'
import ReactDoc from '../documentation/React'
import Arduino from '../documentation/Arduino'
import Download from '../download/Download'
import Legal from '../download/Legal'

function AppRouter (props) {
  return (
    <Router history={createHistory}>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/use-cases'>
          <UseCases subTitle='embeddedSystems' />
        </Route>
        <Route exact path='/use-cases/embedded-systems'>
          <UseCases subTitle='embeddedSystems' />
        </Route>
        <Route exact path='/use-cases/c-c++-integration'>
          <UseCases subTitle='cppIntegration' />
        </Route>
        <Route exact path='/use-cases/inter-microservices'>
          <UseCases subTitle='interMicroservices' />
        </Route>
        <Route exact path='/use-cases/frontend---backend'>
          <UseCases subTitle='frontendBackend' />
        </Route>
        <Route exact path='/technology'>
          <Technology subTitle='overview' />
        </Route>
        <Route exact path='/technology/overview'>
          <Technology subTitle='overview' />
        </Route>
        <Route exact path='/technology/data-types'>
          <Technology subTitle='dataTypes' />
        </Route>
        <Route exact path='/technology/adapter'>
          <Technology subTitle='adapter' />
        </Route>
        <Route exact path='/technology/agent-and-client'>
          <Technology subTitle='agentClient' />
        </Route>
        <Route exact path='/examples'>
          <Examples />
        </Route>
        <Route exact path='/examples/c++-in-nodejs'>
          <CppNode />
        </Route>
        <Route exact path='/examples/c++-in-python'>
          <CppPython />
        </Route>
        <Route exact path='/examples/c++-agent'>
          <CppAgent />
        </Route>
        <Route exact path='/examples/arduino-agent'>
          <ArduinoAgent />
        </Route>
        <Route exact path='/examples/r-agent'>
          <RAgent />
        </Route>
        <Route exact path='/examples/node-agent'>
          <NodeAgent />
        </Route>
        <Route exact path='/examples/node-client'>
          <NodeClient />
        </Route>
        <Route exact path='/examples/react-app'>
          <ReactApp />
        </Route>
        <Route exact path='/docs'>
          <Documentation />
        </Route>
        <Route exact path='/docs/remote-protocol'>
          <RemoteProtocol />
        </Route>
        <Route exact path='/docs/cpp'>
          <Cpp />
        </Route>
        <Route exact path='/docs/nodejs'>
          <NodeJs />
        </Route>
        <Route exact path='/docs/python'>
          <Python />
        </Route>
        <Route exact path='/docs/react'>
          <ReactDoc />
        </Route>
        <Route exact path='/docs/arduino'>
          <Arduino />
        </Route>
        <Route exact path='/download'>
          <Download />
        </Route>
        <Route exact path='/legal'>
          <Legal />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default AppRouter
