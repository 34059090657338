import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import TextImage from '../app/TextImage'
import technology from './technology.jpeg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class Overview extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <TextImage image={technology}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          Overview
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          VRPC - Variadic Remote Procedure Calls - is a modern implementation
          of the old RPC (remote procedure calls) idea.
          Basically, it allows to directly call functions written in
          any programming language by functions written in any other (or
          the same) programming language.
        </Typography>
        <Typography className={classes.body} align='justify' variant='body1'>
          VRPC is modern as existing code can be made remotely callable:
          <ul>
            <li>without having to change it</li>
            <li>without the need to program any binding code or complex schemas</li>
            <li>without the need to run a server</li>
          </ul>
          VRPC is unique as it supports the full power of the programming language
          in use, such as:
          <ul>
            <li>automated memory handling</li>
            <li>language native and custom data type support</li>
            <li>callback mechanisms</li>
            <li>asynchronicity (i.e. event-loop integration)</li>
            <li>exception handling</li>
            <li>object orientation</li>
            <li>lamda and closures support</li>
          </ul>
          Depending on the targeted technologies, VRPC ships as a library (static
          or dynamic linkage), executable or source package.
        </Typography>
      </TextImage>
    )
  }
}

export default withStyles(styleSheet)(Overview)
