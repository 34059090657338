import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import TextImage from '../app/TextImage'

import adapter from './adapter.svg'

const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class Adapter extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <TextImage sizeHint='contain' image={adapter}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          Adapter
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          The Adapter non-intrusively wraps around existing functions and standardizes
          their interface to solely work with JSON.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          The underlying native functions are called after extracting and
          translating the arguments as provided within a JSON object.
          Potential exceptions and return values
          are captured and mapped back to JSON.
          If namespaces, classes and objects are involved the adapter manages
          the construction, life-time and corresponding memory under the hood.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          All functions are uniquely addressed using two properties: <i>context </i>
          and <i>method</i>.
          In case of static or free standing functions <i>context</i> reflects
          the class or module name (including potential namespaces). In case of
          member functions <i>context</i> refers to the unique id of the
          underlying instance (object pointer).
          The <i>method</i> property simply represents the stringified function name.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          VRPC automatically generates the Adapter code given a list of function
          names to be made remotely callable.
        </Typography>
      </TextImage>
    )
  }
}

export default withStyles(styleSheet)(Adapter)
