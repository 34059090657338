import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  Button
} from '@material-ui/core'

const styleSheet = (theme) => ({
  button: {
    margin: theme.spacing(4),
    minWidth: 200
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(6)
  }
})

function Buttons ({ classes, history }) {
  return (
    <div className={classes.root}>
      <Button
        // color='secondary'
        size='large'
        variant='contained'
        className={classes.button}
        onClick={() => history.push('/technology/overview')}
      >
        How does it work?
      </Button>
      <Button
        // color='secondary'
        size='large'
        className={classes.button}
        variant='contained'
        onClick={() => history.push('/download')}
      >
        Get VRPC now!
      </Button>
    </div>
  )
}

export default withRouter(withStyles(styleSheet)(Buttons))
