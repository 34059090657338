import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import scrollToComponent from 'react-scroll-to-component'
import TopBar from '../app/TopBar'
import ExampleCards from './ExampleCards'

const styleSheet = theme => ({
  root: {
    width: '100%'
  }
})

class Examples extends React.Component {

  scrollTo (subTitle) {
    scrollToComponent(
      this[subTitle],
      { offset: -100, align: 'top', duration: 500, ease: 'inSine' }
    )
  }

  render () {
    const { classes, subTitle, width } = this.props
    setTimeout(() => this.scrollTo(subTitle), 100)
    return (
      <div className={classes.root}>
        <Helmet>
          <title>VRPC - Examples</title>
          <meta
            name='description'
            content='See how you can make your existing code callable by another language, even across the internet.'
          />
        </Helmet>
        <TopBar title='Examples' />
        <ExampleCards />
      </div>
    )
  }
}

export default withStyles(styleSheet)(Examples)
