import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core/'

import downloadCpp from './download-c++.jpeg'
import downloadNodejs from './download-nodejs.jpeg'
import downloadPython from './download-python.jpeg'
import pypiLogo from './pypi-logo.png'
import npmLogo from './npm-logo.png'
import tarLogo from './tar-logo.png'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    maxWidth: 400,
    minWidth: 290,
    minHeight: 460,
    margin: theme.spacing(2),
    opacity: 0.9
  },
  largeCard: {
    width: 400
  },
  media: {
    height: 200
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  logo: {
    padding: theme.spacing(2)
  }
})

function Technologies ({ classes, width }) {
  const cardClass = isWidthDown('md', width)
    ? classes.card
    : `${classes.card} ${classes.largeCard}`
  const path = `${process.env.PUBLIC_URL}/download`
  return (
    <div className={classes.root}>
      <Card raised className={cardClass}>
        <CardMedia className={classes.media} image={downloadCpp} />
        <CardContent>
          <Typography align='justify' paragraph component='p'>
            The archive contains the C++ VRPC header-only code. As it is
            entirely dependency free it runs on any operating system (windows,
            mac, linux) and any platform (x86, arm, etc.). Use it freely without
            any limitations.
          </Typography>
          <div className={classes.link}>
            <img style={{ marginRight: 16 }} src={tarLogo} alt='' />
            <a href={`${path}/vrpc-3.0.0.tar.gz`} download>
              vrpc-3.0.0.tar.gz
            </a>
          </div>
        </CardContent>
      </Card>

      <Card raised className={cardClass}>
        <CardMedia className={classes.media} image={downloadNodejs} />
        <CardContent>
          <Typography align='justify' paragraph component='p'>
            Both agent, client and even the C++ embedding feature are freely
            available without limitations. The code is distributed as single npm
            package. You can find it here:
          </Typography>
          <div className={classes.link}>
            <img src={npmLogo} alt='' />
            <a href='https://www.npmjs.com/package/vrpc'>
              www.npmjs.com/package/vrpc
            </a>
          </div>
        </CardContent>
      </Card>

      <Card raised className={cardClass}>
        <CardMedia className={classes.media} image={downloadPython} />
        <CardContent>
          <Typography component='p'>
            Both agent, client and even the C++ embedding feature are freely
            available without limitations. The code is distributed as single pip
            package. You can find it here:
          </Typography>
          <div className={classes.link}>
            <img className={classes.logo} src={pypiLogo} alt='' />
            <a href='https://pypi.org/project/vrpc'>pypi.org/package/vrpc</a>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default withStyles(styleSheet)(withWidth()(Technologies))
