import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  withStyles
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const styleSheet = theme => ({
  nested: {
    paddingLeft: theme.spacing()
  }
})

class MenuEntry extends React.Component {

  constructor (props) {
    super(props)
    this.state = { expanded: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    const { title, subTitles } = this.props
    if (!subTitles) {
      let nav = title.toLowerCase().replace(/ |\//g, '-')
      this.props.history.push(`/${nav}`)
    } else {
      this.setState(state => ({ expanded: !state.expanded }))
    }
  }

  handleMenuItemClick (title, subTitle) {
    this.props.onClick()
    title = title.toLowerCase().replace(/ |\//g, '-')
    subTitle = subTitle.toLowerCase().replace(/ |\//g, '-')
    this.props.history.push(`/${title}/${subTitle}`)
  }

  render () {
    const { classes, title, subTitles } = this.props
    let nestedList
    if (subTitles) {
      nestedList = subTitles.map((subTitle) =>
        <ListItem
          key={subTitle}
          button
          className={classes.nested}
          onClick={() => this.handleMenuItemClick(title, subTitle)}
        >
          <ListItemText inset primary={subTitle} />
        </ListItem>
      )
    }
    return (
      <div>
        <ListItem button onClick={this.handleClick}>
          <ListItemText
            primary={title}
            title={title}
            className={classes.title}
          />
          {nestedList && (this.state.expanded ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {nestedList &&
          <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {nestedList}
            </List>
          </Collapse>
        }
      </div>
    )
  }
}

export default withRouter(withStyles(styleSheet)(MenuEntry))
