import React from 'react'
import {
  List,
  Divider,
  withStyles
} from '@material-ui/core'
import MenuEntry from './MenuEntry'
import vrpcLogo from './v-simple.svg'

const styleSheet = theme => ({
  logoBox: {
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  }
})

class MenuContent extends React.Component {

  render () {
    const { classes, onClick } = this.props
    return (
      <List>
        <div className={classes.logoBox} >
          <img alt='' src={vrpcLogo} style={{ width: '64px', height: '64px' }} />
        </div>
        <Divider />
        <MenuEntry
          onClick={onClick}
          title='Use Cases'
          subTitles={[
            'Embedded Systems',
            'C/C++ Integration',
            'Inter Microservices',
            'Frontend - Backend'
          ]}
        />
        <MenuEntry
          onClick={onClick}
          title='Technology'
          subTitles={[
            'Overview',
            'Data Types',
            'Adapter',
            'Agent and Client'
          ]}
        />
        <MenuEntry
          onClick={onClick}
          title='Examples'
        />
        <MenuEntry
          onClick={onClick}
          title='Docs'
        />
        <MenuEntry
          onClick={onClick}
          title='Download'
        />
      </List>
    )
  }
}

export default withStyles(styleSheet)(MenuContent)
