import React from 'react'
import {
  Typography,
  withStyles
} from '@material-ui/core'

import ImageText from '../app/ImageText'
import agentClient from './agent-client.svg'
const styleSheet = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  body: {
    fontSize: '17px'
  }
})

class AgentClient extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <ImageText sizeHint='contain' image={agentClient}>
        <Typography className={classes.title} paragraph align='left' variant='body1'>
          Client and Agent
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          After having standardized a function (using the Adapter), it needs to be
          interacted with. This "calling part" of the process is done using the
          Client. The Client is complementary to the Adapter in producing JSON when
          called and receiving values or exceptions on return.
          Depending on the use case, Clients come in two flavors, local and remote.
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          For cross-language bindings (e.g. C++ in Node.js) VRPC compiles the adapted
          code and the Local Client into a single application. Hence, function calls
          are abstracted via JSON but do not use a network, instead are direct and
          extremely fast (see top figure).
        </Typography>
        <Typography className={classes.body} paragraph align='justify' variant='body1'>
          For all use-cases requiring remote locations between original code and Client,
          (see bottom figure) VRPC compiles the adapted code and the Remote Client
          into separate applications that will use a network for communication.
          The serving application that makes original code remotely callable is
          called Agent.
          The network communication uses MQTT, supports TLS encryption,
          and supports externally plug-able and fine-grained
          access control down to function level.
        </Typography>
      </ImageText>
    )
  }
}

export default withStyles(styleSheet)(AgentClient)
