import React from 'react'
import scrollToComponent from 'react-scroll-to-component'
import { Helmet } from 'react-helmet'
import {
  withStyles
} from '@material-ui/core'

import TopBar from '../app/TopBar'
import Overview from './Overview'
import DataTypes from './DataTypes'
import Adapter from './Adapter'
import AgentClient from './AgentClient'

const styleSheet = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  spacer: {
    minHeight: 100
  },
  bottomSpacer: {
    minHeight: 200
  }
})

class UseCases extends React.Component {

  scrollTo (subTitle) {
    scrollToComponent(
      this[subTitle],
      { offset: 0, align: 'middle', duration: 500, ease: 'inSine' }
    )
  }

  render () {
    const { classes, subTitle } = this.props
    setImmediate(() => this.scrollTo(subTitle))
    return (
      <div>
        <Helmet>
          <title>VRPC - Technology</title>
          <meta
            name='description'
            content='VRPC is dead simple to use and its design concepts are quickly explained.'
          />
        </Helmet>
        <TopBar title='Technology'>
          <div className={classes.root}>
            <Overview ref={(ref) => (this.overview = ref)} />
            <div className={classes.spacer} />
            <DataTypes ref={(ref) => (this.dataTypes = ref)} />
            <div className={classes.spacer} />
            <Adapter ref={(ref) => (this.adapter = ref)} />
            <div className={classes.spacer} />
            <AgentClient ref={(ref) => (this.agentClient = ref)} />
            <div className={classes.bottomSpacer} />
          </div>
        </TopBar>
      </div>
    )
  }
}

export default withStyles(styleSheet)(UseCases)
