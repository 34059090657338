import React from 'react'
import {
  Paper,
  Typography,
  withStyles,
  Link
} from '@material-ui/core'

const styleSheet = theme => ({
  title: {
    fontSize: '20px'
  },
  body: {
    fontSize: '17px'
  },
  paper: {
    margin: 'auto',
    padding: theme.spacing(2),
    // paddingBottom: theme.spacing(0),
    maxWidth: 800,
    marginTop: 200,
    backgroundColor: '#FEFEFE'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  item: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '10px'
  }
})

function Legal ({ classes }) {
  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.box}>
          <Typography className={classes.item}>
            <Link href='https://heisenware.com'>Heisenware GmbH</Link>
          </Typography>
          <Typography className={classes.item}>
            <Link href='https://www.linkedin.com/in/burkhard-heisen'>Dr. Burkhard Heisen</Link>
          </Typography>
          <Typography className={classes.item}>Levisohnweg 3</Typography>
          <Typography className={classes.item}>22081 Hamburg</Typography>
          <Typography className={classes.item}>contact@heisenware.com</Typography>
        </div>
        <div className={classes.box}>
          <Typography className={classes.item}>USt - IdNr. gem. § 27a UStG: DE318348613</Typography>
          <Typography className={classes.item}>Inhaltlich Verantwortlicher gem. § 55 II RStV: Dr. Burkhard Heisen</Typography>
        </div>
        <div className={classes.box}>
          <Typography className={classes.item}>Bildmaterial lizensiert durch:
            <Link href='https://stock.adobe.com/de/license-terms'> Adobe Stock</Link>
          </Typography>
        </div>
      </Paper>
    </div>
  )
}

export default withStyles(styleSheet)(Legal)
