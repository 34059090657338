import 'babel-polyfill'
import 'typeface-roboto'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'

import { unregister } from './registerServiceWorker'
import AppRouter from './components/app/AppRouter'

const vrpcTheme = createMuiTheme({
  typography: {
    fontSize: 14
  },
  palette: {
    primary: {
      main: '#424242'
    },
    secondary: {
      main: '#e4d97f',
      contrastText: '#000000'
    }
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={vrpcTheme} >
    <AppRouter />
  </MuiThemeProvider>,
  document.getElementById('root')
)

unregister()
