import React, { Component } from 'react'
import {
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'
import TopBar from '../app/TopBar'
import MarkdownRenderer from './MarkdownRenderer'
import cppAgent from './cpp_agent_logo.png'

const styleSheet = theme => ({
  '@global': {
    body: {
      fontSize: '14px',
      lineHeight: 1.65,
      letterSpacing: 0.1,
      fontFamily: 'Source Sans Pro, Lucida Grande, sans-serif'
    },
    a: {
      color: 'darkblue'
    },
    pre: {
      overflow: 'auto',
      padding: theme.spacing(2),
      backgroundColor: '#f5f5f5'
    }
  },
  root: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto'
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
    backgroundColor: '#424242'
  },
  content: {
    padding: theme.spacing(4),
    opacity: 0.9,
    marginBottom: theme.spacing(6)
  },
  img: {
   marginLeft: theme.spacing(4),
   height: 180,
   width: 180
 }
})

class CppAgent extends Component {

  constructor (props) {
    super(props)
    this.state = { example1: null, example2: null }
  }

  async componentDidMount () {
    const repo = 'https://raw.githubusercontent.com/heisenware/vrpc-hpp/master'
    const ex1 = await window.fetch(`${repo}/examples/01-foo/README.md`)
    const ex2 = await window.fetch(`${repo}/examples/02-bar/README.md`)
    const example1 = await ex1.text()
    const example2 = await ex2.text()
    this.setState({ example1, example2 })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <TopBar title='Examples'>
          <div className={classes.root}>
            <Paper elevation={10} className={classes.section}>
              <Typography color='secondary' variant='h4'>
                C++ Agent
              </Typography>
              <img className={classes.img} alt='' src={cppAgent} />
            </Paper>
            <Paper elevation={10} className={classes.content}>
              <MarkdownRenderer text={this.state.example1} />
            </Paper>
            <Paper elevation={10} className={classes.content}>
              <MarkdownRenderer text={this.state.example2} />
            </Paper>
          </div>
        </TopBar>
      </div>
    )
  }
}

export default withStyles(styleSheet)(CppAgent)
