import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withRouter } from 'react-router-dom'
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core'
import LoginIcon from '@material-ui/icons/AccountCircle'
import LiveIcon from '@material-ui/icons/PlayCircleFilled'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import NavigationBar from './NavigationBar'
import HorizontalMenuEntry from './HorizontalMenuEntry'

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 11,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

const styleSheet = (theme) => ({
  '@global': {
    html: {
      boxSizing: 'border-box'
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit'
    },
    body: {
      margin: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: '#FFFFFF',
      color: theme.palette.text.primary,
      lineHeight: '1.2',
      overflowX: 'hidden',
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale' // Antialiasing.
    },
    a: {
      color: 'darkblue'
    }
  },
  title: {
    marginLeft: theme.spacing(4)
  },
  appBar: {
    '-webkit-transition': theme.transitions.create('width'),
    '-moz-transition': theme.transitions.create('width'),
    '-o-transition': theme.transitions.create('width'),
    transition: theme.transitions.create('width'),
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    height: '90px',
    alignContent: 'center',
    justifyContent: 'center'
  },
  navIcon: {
    opacity: 1,
    '-webkit-transition': 'opacity 0.5s ease',
    '-moz-transition': 'opacity 0.5s ease',
    '-o-transition': 'opacity 0.5s ease',
    transition: 'opacity 0.5s ease',
  },
  horizontalMenu: {
    display: 'flex',
    flexDirection: 'row',
    '-webkit-transition': 'opacity 0.5s ease',
    '-moz-transition': 'opacity 0.5s ease',
    '-o-transition': 'opacity 0.5s ease',
    transition: 'opacity .5s ease',
    opacity: 0
  },
  content: {
    width: '100%',
    marginTop: 120,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    navigationBar: {
      width: '250px'
    },
    appBarShift: {
      width: 'calc(100% - 250px)'
    },
    contentShift: {
      marginLeft: '250px'
    },
    hideWhileLarge: {
      opacity: 0
    },
    showWhileLarge: {
      opacity: 1
    }

  },
  grow: {
    flexGrow: 1
  }
})

class TopBar extends React.Component {
  state = {
    open: false,
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ open: !this.state.open }));
  }

  render () {
    const { children, classes, history, title, withMenu, width } = this.props
    const appBarClass = withMenu ? `${classes.appBar} ${classes.appBarShift}` : classes.appBar
    const navIconClass = `${classes.navIcon} ${classes.hideWhileLarge}`
    const horizontalMenuClass = `${classes.horizontalMenu} ${classes.showWhileLarge}`
    const showHorizontalMenu = isWidthUp('md', width)
    return (
      <div>
        <AppBar className={appBarClass}>
          <Toolbar>
            <IconButton
              className={navIconClass}
              aria-label='Menu'
              onClick={this.handleDrawerToggle}
            >
              <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
            {title &&
              <Typography
                color='secondary'
                className={classes.title}
                variant='h5'
              >
                {title}
              </Typography>
            }
            <div className={classes.grow} />
              {!withMenu && showHorizontalMenu &&
              <div className={horizontalMenuClass}>
              <IconButton
                style={{ color: '#fff' }}
                title='Home'
                onClick={() => history.push('/')}
              >
                <HomeIcon />
              </IconButton>
              <HorizontalMenuEntry
                title='Use Cases'
                subTitles={[
                  'Embedded Systems',
                  'C/C++ Integration',
                  'Inter Microservices',
                  'Frontend - Backend'
                ]}
              />
              <HorizontalMenuEntry
                title='Technology'
                subTitles={[
                  'Overview',
                  'Data Types',
                  'Adapter',
                  'Agent and Client'
                ]}
              />
              <HorizontalMenuEntry
                title='Examples'
              />
               <HorizontalMenuEntry
                title='Docs'
              />
              <HorizontalMenuEntry
                title='Download'
              />
            </div>
              }
              <IconButton
                style={{ color: '#fff' }}
                title='Live'
                onClick={() => window.open(`https://live.${window.location.hostname}`, '_blank')}
              >
                <LiveIcon />
              </IconButton>
            {/* </StyledBadge> */}
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          {children}
        </div>
        <NavigationBar open={this.state.open} withMenu={withMenu} />
      </div>
    )
  }
}

export default withRouter(withStyles(styleSheet)(withWidth()(TopBar)))
